import React, { useEffect } from 'react'
import { hotjar } from 'react-hotjar'
import axios from '@/axios'
import AppBuilderContextProvider from './AppBuilderContextProvider'
import TrademarkSearchContextProvider from './TrademarkSearchContextProvider'
import GetStartedContextProvider from './GetStartedContextProvider'
import { Location } from '@gatsbyjs/reach-router'
export const GlobalStateContext = React.createContext()
export const GlobalDispatchContext = React.createContext()
import { setAffiliateId, getLocaleCookie, switchLocale } from '@/utils'
import { navigate } from 'gatsby-link'

const initialState = {
  locale: 'en-US',
  location: '/',
  loading: true,
  showModal: false,
  searchLocale: 'en-US',
  partnerRef: null,
  isPartner: false,
}

function reducer(state, action) {
  switch (action.type) {
    case 'SET_LOCALE': {
      return {
        ...state,
        locale: action.payload.locale
      }
    }
    case 'SET_LOADING': {
      return {
        ...state,
        loading: action.payload.loading
      }
    }
    case 'SET_SHOW_MODAL': {
      return {
        ...state,
        showModal: action.payload
      }
    }
    case 'SET_LOCATION': {
      return {
        ...state,
        location: action.payload.location,
        search: action.payload.search
      }
    }
    case 'SET_SEARCH_LOCALE': {
      return {
        ...state,
        searchLocale: action.payload.searchLocale
      }
    }
    case 'SET_TRADEMARK': {
      return {
        ...state,
        trademark: action.payload.trademark
      }
    }
    case 'SET_PARTNER_REF': {
      return {
        ...state,
        partnerRef: action.payload.partnerRef
      }
    }
    case 'SET_PARTNER': {
      return {
        ...state,
        isPartner: action.payload.value
      }
    }
    default:
      throw new Error('invalid action type')
  }
}

// retrieves the gclid from _gac cookie
export const getGclid = () => {
  const cookiesObj = {}
  // Parse cookies and return gclid value

  const allCookies = document.cookie.split(';')

  allCookies.forEach((c) => {
    const cookie = c.trim().split('=')
    cookiesObj[cookie[0]] = cookie[1]
  })
  const gclidCookie =
    cookiesObj[`_gac_${process.env.GATSBY_GOOGLE_ANALYTICS_ID}`]
  if (gclidCookie) {
    return gclidCookie?.split('.')[2]
  }
  return ''
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const initiateHotjar = () => {
    const hjid = 3252832
    const hjsv = 6
    hotjar.initialize(hjid, hjsv)
  }

  const getLocale = async () => {
    let locale = await getLocaleCookie()
    let isHome =
      window.location.pathname === '/en-ca/' || window.location.pathname === '/'

    const { data } = await axios.get(`/my/location`)
    // if there is no locale set in the cookie then display locale modal to set it
    if (!locale) {
      try {
        locale = data?.country === 'CA' ? 'en-CA' : 'en-US'
      } catch (e) {
        locale = 'en-US'
      }
      dispatch({ type: 'SET_LOCALE', payload: { locale: locale } })
      dispatch({ type: 'SET_SHOW_MODAL', payload: true })
    } else if (locale) {
      const checkUrlLocale = window.location.pathname.includes(
        locale.toLowerCase()
      )
      // if the user's cookie is set to en-ca and they try to land on a us page OR if the cookie is set to en-us and they land on a canadian page navigate
      if (!checkUrlLocale && locale.toLowerCase() !== 'en-us') {
        const newPath = switchLocale(
          `${location.pathname}${location.search}`,
          locale
        )
        navigate(newPath)
      }

      const urlParams = new URLSearchParams(window.location.search)
      if (!urlParams.get('queryId')) {
        dispatch({
          type: 'SET_SEARCH_LOCALE',
          payload: { searchLocale: locale }
        })
      }
      dispatch({ type: 'SET_LOCALE', payload: { locale: locale } })
    }

    if (!data.isMobile && process.env.GATSBY_APP_ENV === 'production') {
      initiateHotjar()
    }
    dispatch({ type: 'SET_LOADING', payload: { loading: false } })
  }

  useEffect(() => {
    getLocale()
    window.dataLayer = window.dataLayer || []
  }, [])

  // set affiliate cookie if it exists
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const sscid = urlParams.get('sscid')
    if (sscid) {
      setAffiliateId(sscid)
    }
  }, [])

  return (
    <Location>
      {({ location }) => (
        <GlobalStateContext.Provider value={state}>
          <GlobalDispatchContext.Provider value={dispatch}>
            {/* App-builder state */}
            <AppBuilderContextProvider>
              <GetStartedContextProvider>
                <TrademarkSearchContextProvider>
                  {children}
                </TrademarkSearchContextProvider>
              </GetStartedContextProvider>
            </AppBuilderContextProvider>
          </GlobalDispatchContext.Provider>
        </GlobalStateContext.Provider>
      )}
    </Location>
  )
}
export default GlobalContextProvider
