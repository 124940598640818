import React from 'react'
import { APP_BUILDER } from '../constants'

export const AppBuilderStateContext = React.createContext()
export const AppBuilderDispatchContext = React.createContext()

const intialState = {
  app_uuid: null,
  appData: null,
  step: {
    currentActive: 0,
    status: 0
  },
  validSteps: {
    step0: {
      valid: false
    },
    step1: {
      valid: true
    },
    step2: {
      valid: true
    },
    step3: {
      valid: true
    }
  },
  authed: false,
  recordNotFound: false,
  searchData: {
    searchTerm: '',
    baseApplication: {},
    additionalApplication: [],
    relatedIndustries: []
  },
  prescreenSearchData: {},
  trademark: {},
  downloaded: false,
  urls: {
    filingInstructionsUrl: '',
    applicationUrl: ''
  }
}

function reducer(state, action) {
  switch (action.type) {
    case APP_BUILDER.ACTIONS.SET_APP_UUID: {
      return {
        ...state,
        app_uuid: action.payload.value
      }
    }
    case APP_BUILDER.ACTIONS.SET_APP_DATA: {
      return {
        ...state,
        appData: action.payload.value
      }
    }
    case APP_BUILDER.ACTIONS.UPDATE_STEP: {
      return {
        ...state,
        step: {
          currentActive: action.payload.value.currentActive,
          status: action.payload.value.status
        }
      }
    }
    case APP_BUILDER.ACTIONS.SET_AUTHED: {
      return {
        ...state,
        authed: action.payload.value
      }
    }
    case APP_BUILDER.ACTIONS.SET_RECORD_NOT_FOUND: {
      return {
        ...state,
        recordNotFound: action.payload.value
      }
    }
    case APP_BUILDER.ACTIONS.UPDATE_BASE_APPLICATION: {
      return {
        ...state,
        searchData: {
          ...state.searchData,
          baseApplication: action.payload.value
        }
      }
    }
    case APP_BUILDER.ACTIONS.UPDATE_ADDITIONAL_APPLICATION: {
      return {
        ...state,
        searchData: {
          ...state.searchData,
          additionalApplication: action.payload.value
        }
      }
    }
    case APP_BUILDER.ACTIONS.UPDATE_RELATED_INDUSTRIES: {
      return {
        ...state,
        searchData: {
          ...state.searchData,
          relatedIndustries: action.payload.value
        }
      }
    }
    case APP_BUILDER.ACTIONS.SET_SETP_VALID: {
      return {
        ...state,
        validSteps: {
          ...state.validSteps,
          [action.payload.key]: { valid: action.payload.value }
        }
      }
    }
    case APP_BUILDER.ACTIONS.SET_PRESCREEN_SEARCH_DATA: {
      return {
        ...state,
        prescreenSearchData: action.payload.value
      }
    }
    case APP_BUILDER.ACTIONS.SET_TRADEMARK_DATA: {
      return {
        ...state,
        trademark: action.payload.value
      }
    }
    case APP_BUILDER.ACTIONS.SET_URLS: {
      return {
        ...state,
        urls: {
          filingInstructionsUrl: action.payload.value.filingInstructionsUrl,
          applicationUrl: action.payload.value.applicationUrl
        }
      }
    }
    case APP_BUILDER.ACTIONS.RESET_STATE: {
      return {
        app_uuid: null,
        appData: null,
        step: {
          currentActive: 0,
          status: 0
        },
        validSteps: {
          step0: {
            valid: false
          },
          step1: {
            valid: true
          },
          step2: {
            valid: true
          },
          step3: {
            valid: true
          }
        },
        authed: false,
        recordNotFound: false,
        searchData: {
          searchTerm: '',
          baseApplication: {},
          additionalApplication: [],
          relatedIndustries: []
        },
        prescreenSearchData: {},
        trademark: {},
        downloaded: false
      }
    }
    default:
      throw new Error('invalid action type')
  }
}

const AppBuilderContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, intialState)
  return (
    <AppBuilderStateContext.Provider value={state}>
      <AppBuilderDispatchContext.Provider value={dispatch}>
        {children}
      </AppBuilderDispatchContext.Provider>
    </AppBuilderStateContext.Provider>
  )
}

export default AppBuilderContextProvider
