import React, { createContext, useState, useEffect } from 'react'
import Errors from '@/errors'
import axios from '@/axios'
import { navigate } from 'gatsby'
import { buildPath, validateEmail } from '@/utils'
import fbTrack from './facebook'
import { isEmpty, trim } from 'lodash'

export const TrademarkSearchContext = createContext(null)

const TrademarkSearchContextProvider = ({ children }) => {
  const [loading, setLoading] = useState(true)
  const [search, setSearch] = useState({
    fullName: '',
    trademark: '',
    email: '',
    country: '',
    tlds: [],
    businessCategories: [],
    goodsServices: [],
    type: '',
    preview: true,
    alternateSpellings: [],
    trademarkLogo: []
  })

  const [rawGoodsAndServices, setRawGoodsAndServices] = useState({})
  const [fetchGoodsAndServices, setFetchGoodsAndServices] = useState(false)
  const [niceClasses, setNiceClasses] = useState([])

  const [touched, setTouched] = useState({
    fullName: false,
    email: false,
    businessCategories: false,
    goodsServices: false,
    trademark: false,
    trademarkLogoSrc: false
  })

  const [results, setResults] = useState(null)
  const [searchError, setSearchError] = useState(null)
  const [validationErrors, setValidationErrors] = useState(new Errors())
  const [view, setView] = useState('get-started')
  const [query, setQuery] = useState(null)
  const [governmentFees, setGovernmentFees] = useState(false)
  const [validated, setValidated] = useState(false)

  const [showTrademarkSelectionError, setShowTrademarkSelectionError] =
    useState(false)

  useEffect(() => {
    fbTrack('track', 'CustomizeProduct')
  }, [JSON.stringify(search)])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    setQuery(urlParams.get('queryId'))
  }, [])

  useEffect(() => {
    setFetchGoodsAndServices(true)
  }, [])

  useEffect(() => {
    if (
      !validateEmail(search.email) ||
      isEmpty(trim(search.fullName)) ||
      isEmpty(trim(search.type))
    ) {
      setValidated(false)
    } else if (
      (search.type !== 'logo' && isEmpty(trim(search.trademark))) ||
      (search.type === 'logo' && isEmpty(search.trademarkLogo[0]))
    ) {
      setValidated(false)
    } else if (search.businessCategories.length <= 0) {
      setValidated(false)
    } else {
      setValidated(true)
    }

    if (!isEmpty(search.email) && !validateEmail(search.email)) {
      setTouched({
        ...touched,
        email: true
      })
    }
  }, [search])

  const fetchSearchResults = async (searchQuery) => {
    axios
      .get(`/search/${searchQuery}`)
      .then((response) => {
        const json = response.data
        setResults({
          ...json.results,
          overallRisk: json.overall_risk,
          orderStatus: json.order_status
        })
        if (json.hasOrder && json.builder) {
          const lang = json.country === 'ca' ? 'en-CA' : 'en-US'
          navigate(buildPath(lang, `/app-builder/${json.builder}`), {
            replace: true
          })
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setSearchError('queryError')
      })
  }

  const persistLocalStorage = () => {
    localStorage.setItem('haloo:root', JSON.stringify(search))
  }

  const getLocalStorage = () => {
    return localStorage.getItem('haloo:root')
  }

  const removeLocalStorage = () => {
    localStorage.removeItem('haloo:root')
  }

  const resetPageInStorage = () => {
    localStorage.setItem(
      'haloo:root',
      JSON.stringify({
        state: search,
        page: 0
      })
    )
  }

  return (
    <TrademarkSearchContext.Provider
      value={{
        search,
        setSearch,
        loading,
        setLoading,
        results,
        setResults,
        view,
        setView,
        query,
        setQuery,
        searchError,
        setSearchError,
        setGovernmentFees,
        governmentFees,
        validationErrors,
        setValidationErrors,
        setTouched,
        touched,
        persistLocalStorage,
        getLocalStorage,
        removeLocalStorage,
        resetPageInStorage,
        rawGoodsAndServices,
        setRawGoodsAndServices,
        fetchSearchResults,
        fetchGoodsAndServices,
        validated,
        showTrademarkSelectionError,
        setShowTrademarkSelectionError,
        niceClasses,
        setNiceClasses
      }}
    >
      {children}
    </TrademarkSearchContext.Provider>
  )
}

export default TrademarkSearchContextProvider
