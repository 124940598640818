/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import React from 'react'
import GlobalContextProvider from './src/context/GlobalContextProvider'
import './src/styles/main.scss'
import * as Sentry from '@sentry/react'
// If taking advantage of automatic instrumentation (highly recommended)
import { BrowserTracing } from '@sentry/tracing'

Sentry.init({
  dsn: process.env.GATSBY_SENTRY_DSN,
  environment: process.env.GATSBY_APP_ENV,
  // This enables automatic instrumentation (highly recommended), but is not
  // necessary for purely manual usage
  beforeSend: function (event) {
    try {
      // Drop all events if query string includes `fbclid` string
      if (location.search.includes('fbclid')) return null
    } catch (e) {
      // Otherwise just let it though
      return event
    }
  },
  integrations: [new BrowserTracing()],
  ignoreErrors: [
    /hydrating/i,
    /hydration/i,
    /Text content does not match server-rendered HTML/
  ],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.2 // Here we will sample 20% of transactions
})

export const wrapRootElement = ({ element }) => {
  return <GlobalContextProvider>{element}</GlobalContextProvider>
}
