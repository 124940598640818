/**
 *
 * @param {String} a // 'track'
 * @param {String} b, // event name
 * @param {Object} c // other options... eg object with currency and amount for purchasing
 */

export default function fbTrack(a, b, c = null) {
  if (
    process.env.GATSBY_APP_ENV === 'production' &&
    typeof fbq === `function`
  ) {
    fbq(a, b, c)
  }
}
