const defaultLocale = 'en-US'
const supportedLocales = [defaultLocale, 'en-CA']
const Cookies = require('js-cookie')
const EmailValidator = require('email-validator')
const map = require('lodash/map')
const uniqBy = require('lodash/uniqBy')
const groupBy = require('lodash/groupBy')

const buildPath = (locale, path) =>
  locale === defaultLocale || locale === null
    ? path
    : `/${locale.toLowerCase()}${path}`

const switchLocale = (urlPath, desiredLocale) => {
  let path = urlPath
  for (const locale of supportedLocales) {
    if (path.startsWith(`/${locale.toLowerCase()}`)) {
      path = path.substring(locale.length + 1)
      break
    }
  }

  // Store the value in a cookie
  setLocaleCookie(desiredLocale)

  return buildPath(desiredLocale, path)
}

/**
 * If the given value is not an array, wrap it in one.
 */
const arrayWrap = (value) => {
  return Array.isArray(value) ? value : [value]
}

const trademarkJSON = (cart) => {
  const item = cart[0]
  const rtn = {
    maxTtl: 1,
    [`${item.type}s`]: {
      qty: item.qty,
      selection: item.selection,
      values: [{ [`${item.type}0`]: item.value }]
    }
  }
  return JSON.stringify(rtn)
}

const stringifyCart = (cart) => {
  const item = cart[0]
  let rtn = {}

  ;['name', 'tagline', 'logo'].forEach((type) => {
    if (item.type === type) {
      rtn[type] = {
        selection: [`1 - initial ${type} Trademark\n`],
        values: [item.value]
      }
    } else {
      rtn[type] = {
        selection: [],
        values: []
      }
    }
  })

  return JSON.stringify(rtn)
}

/**
 * Group Elastic Search business categories by nice class for Trademark
 * application builder
 * @param {Array} industries
 * @param {String} locale
 * @returns Array
 */
const groupByNiceClass = (industries, locale) => {
  const grouped = groupBy(industries, 'nice_class', 'asc')

  let res = {}

  Object.keys(grouped).forEach((key, index) => {
    let price = locale === 'ca' ? '335.93' : '250.00'

    if (index > 0 && locale === 'ca') {
      price = '101.80'
    }

    res[key] = {
      name: `Nice Class ${key}`,
      options: map(uniqBy(grouped[key]), (x) => {
        return {
          text: x?.text?.endsWith(';') ? x.text.slice(0, -1) : x.text,
          expert: true
        }
      }),
      price: price
    }
  })
  return res
}

const setLocaleCookie = (locale, expiry = 30) => {
  if (typeof locale !== 'undefined') {
    Cookies.set('haloo-locale', locale, {
      expires: expiry // expires after 30 days
    })
  }
}

const getLocaleCookie = () => {
  return Cookies.get('haloo-locale') || null
}

const setCookie = (key, value, expiry = 30) => {
  if (typeof value !== 'undefined') {
    Cookies.set(key, value, {
      expires: expiry
    })
  }
}

const getCookie = (key) => {
  return Cookies.get(key) || null
}

/**
 * This function gets the ShareASale Affiliate ID
 * from cookies.
 */
const getAffiliateId = (entry = 'value') => {
  const item = Cookies.get('haloo-sscid')
  let type = null
  let value = null
  if (item) {
    const payload = item.split('__')
    type = payload[0]
    value = payload[1]
  }

  return entry === 'type' ? type : value
}

const setAffiliateId = (value, type = 'sscid') => {
  if (value) {
    const payload = `${type}__${value}`
    Cookies.set('haloo-sscid', payload, {
      expires: 30 // expires after 30 days
    })
  } else {
    Cookies.remove('haloo-sscid')
  }
}

const validateEmail = (email) => {
  return EmailValidator.validate(email)
}

const isDomainAvailable = (domainStatus) => {
  return domainStatus?.toUpperCase() === 'AVAILABLE'
}

/**
 * Get url parameter value
 *
 * @param {string} key The url query key
 * @param {Boolean} caseSensitive Default value is true
 * @returns {string} url param value
 */
const getUrlParam = (key, isCaseSensitive = true) => {
  const urlParams = new URLSearchParams(window.location.search)
  if (isCaseSensitive) {
    return urlParams.get(key)
  } else {
    const newParams = new URLSearchParams()
    for (const [name, value] of urlParams) {
      newParams.append(name.toLowerCase(), value)
    }
    return newParams.get(key)
  }
}

/**
 * Encode img file to base64 to store in browser
 * storage
 * @param {string} selector
 * @param {File} actualFile
 */
const addImageToLocalStorage = (selector, actualFile) => {
  let imgPath = null
  if (actualFile) {
    imgPath = actualFile.file
  } else {
    // get user's uploaded image
    imgPath = document.querySelector(selector).files[0]
  }

  const reader = new FileReader()

  reader.addEventListener(
    'load',
    function () {
      // convert image file to base64 string and save to localStorage
      localStorage.setItem('image', reader.result)
      localStorage.setItem(
        'image-info',
        JSON.stringify({
          name: imgPath?.name,
          type: imgPath?.type
        })
      )
      if (localStorage.getItem('image-url')) {
        localStorage.removeItem('image-url')
      }
    },
    false
  )

  if (imgPath) {
    reader.readAsDataURL(imgPath)
  }
}

/**
 * Decode base64 image to img tag and file.
 *
 * @returns {object} File Object
 */
const getFileFromBase64 = () => {
  let image = document.createElement('img')
  let fileBase64 = localStorage.getItem('image')
  let fileInfo = JSON.parse(localStorage.getItem('image-info'))
  image.src = fileBase64

  let tempFile = new File([fileBase64], fileInfo.name, { type: fileInfo.type })

  return {
    image: image,
    file: tempFile
  }
}

const getPartnerFromSession = () => {
  const partnerSessionObject = JSON.parse(
    sessionStorage.getItem('haloo:partner')
  )
  return partnerSessionObject || null
}

const getApplicantType = (applicantType, country) => {
  if (applicantType === 'a partnership' && country.toLowerCase() === 'us') {
    return 'a llc'
  }
  return applicantType
}

const reorderCountryList = (countries, isPartner = false) => {
  const copy = countries

  if (isPartner) {
    return copy?.filter((country) => country.alpha2 === 'US')
  }

  const headArray = copy.filter((country) => {
    return country.alpha2 === 'CA' || country.alpha2 === 'US'
  })
  const tailArray = copy.filter((country) => {
    if (country.alpha2 === 'CA' || country.alpha2 === 'US') {
      return
    } else {
      return country
    }
  })
  return headArray.concat(tailArray)
}

module.exports = {
  defaultLocale,
  buildPath,
  switchLocale,
  arrayWrap,
  trademarkJSON,
  stringifyCart,
  groupByNiceClass,
  getLocaleCookie,
  setLocaleCookie,
  getAffiliateId,
  setAffiliateId,
  validateEmail,
  isDomainAvailable,
  getUrlParam,
  addImageToLocalStorage,
  getFileFromBase64,
  getPartnerFromSession,
  getApplicantType,
  reorderCountryList,
  setCookie,
  getCookie,
}
