import React from 'react'
import { GET_STARTED, GODADDY_TLDS } from '../constants'

export const GetStartedStateContext = React.createContext()
export const GetStartedDispatchContext = React.createContext()

const tlds = GODADDY_TLDS.split(';');

function findTldInTrademark(trademark) {
  const t = trademark?.trim()?.toLowerCase();
  if (!!t) {
    for (let tld of tlds) {
      if (t.endsWith('.' + tld)) {
        return tld;
      }
    }
  }

  return null;
}

const initialState = {
  tm_uuid: null,
  fullName: '',
  firstName: '',
  lastName: '',
  trademark: '',
  email: '',
  jurisdiction: '',
  businessCategories: [],
  goodsServices: [],
  type: '',
  alternateSpellings: [],
  trademarkLogo: [],
  view: 'get-started',
  rawGoodsServices: {},
  niceClasses: [],
  applicantType: 'an individual',
  applicantName: '',
  address: '',
  aptNumber: '',
  city: '',
  state: '',
  zip: '',
  country: '',
  website: '',
  trademarkTld: ''
}

function reducer(state, action) {
  switch (action.type) {
    case GET_STARTED.ACTIONS.REMOVE_TLD: {
      const trademarkTld = findTldInTrademark(state.trademark)
      if (!trademarkTld) {
        return state;
      }

      const trademarkTldLength = trademarkTld?.length + 1
      return {
        ...state,
        trademark:  state.trademark.slice(0, -1 * trademarkTldLength),
        trademarkTld: state.trademark.slice(-1 * trademarkTldLength),
      }
    }
    case GET_STARTED.ACTIONS.UPDATE_STATE_PROP: {
      return {
        ...state,
        [action.payload.type]: action.payload.value,
        ...(action.payload.type === 'trademark' ? { trademarkTld: null } : {})
      }
    }
    case GET_STARTED.ACTIONS.SET_TM_UUID: {
      return {
        ...state,
        tm_uuid: action.payload.value
      }
    }
    case GET_STARTED.ACTIONS.SET_STATE_FROM_QUERY_PARAM: {
      return {
        ...state,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        trademark: action.payload.trademark,
        email: action.payload.email,
        businessCategories: action.payload.businessCategories,
        country: action.payload.country,
        jurisdiction: action.payload.jurisdiction.toLowerCase(),
        goodsServices: action.payload.goodsServices,
        type: action.payload.type,
        alternateSpellings: action.payload.alternateSpellings,
        trademarkLogo: action.payload.trademarkLogo,
        rawGoodsServices: action.payload.rawGoodsServices,
        niceClasses: action.payload.niceClasses,
        view: action.payload.view
      }
    }
    case GET_STARTED.ACTIONS.UPDATE_CURRENT_VIEW: {
      return {
        ...state,
        view: action.payload.value
      }
    }
    case GET_STARTED.ACTIONS.RESET_ADDRESS: {
      return {
        ...state,
        address: '',
        aptNumber: '',
        city: '',
        state: '',
        zip: ''
      }
    }
    case GET_STARTED.ACTIONS.RESET_STATE: {
      return {
        tm_uuid: null,
        fullName: '',
        trademark: '',
        email: '',
        country: '',
        businessCategories: [],
        goodsServices: [],
        type: '',
        alternateSpellings: [],
        trademarkLogo: [],
        view: 'get-started',
        rawGoodsServices: {},
        niceClasses: [],
        applicantType: 'an individual',
        applicantName: '',
        address: '',
        aptNumber: '',
        city: '',
        state: '',
        zip: '',
        website: ''
      }
    }
    default:
      throw new Error('invalid action type')
  }
}

const GetStartedContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  return (
    <GetStartedStateContext.Provider value={state}>
      <GetStartedDispatchContext.Provider value={dispatch}>
        {children}
      </GetStartedDispatchContext.Provider>
    </GetStartedStateContext.Provider>
  )
}

export default GetStartedContextProvider
