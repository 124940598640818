import axios from 'axios'
import { getAffiliateId } from './utils'
const Agent = require('agentkeepalive')

const keepAliveAgent = new Agent({
  keepAlive: true
  // maxSockets: 100,
  // maxFreeSockets: 10,
  // timeout: 60000, // active socket keepalive for 60 seconds
  // freeSocketTimeout: 30000 // free socket keepalive for 30 seconds
})

export default axios.create({
  baseURL: `${process.env.GATSBY_LARAVEL_URL}/api/v2`,
  httpAgent: keepAliveAgent,
  httspAgent: keepAliveAgent,
  headers: {
    'X-APPLICATION-ID': process.env.GATSBY_LARAVEL_CLIENT_ID,
    'X-APPLICATION-KEY': process.env.GATSBY_LARAVEL_CLIENT_KEY,
    Accept: 'application/json',
    'Content-Type': 'application/json',
    sscid: getAffiliateId('value') || '',
    sscidtype: getAffiliateId('type') || ''
  }
})
